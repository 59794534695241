$env: undefined;
@import '../../tools/common';
.Bread {
  display: inline-block;
  width: 100%;
}
.breadMain {
  font-size: 14px;
  font-weight: 400;
  color: #999;
 
  // height: 100px;
  background-color: rgb(255, 255, 255);
  padding:20px 25px 0px 25px;
  width: $layout;
  margin:0 auto;
  
 
  .desc{
    line-height: 30px;
  }
}