$env: undefined;
@import "../../tools/common";
$border-color:#d9d9d9;

header {
  width: 100%;
  // max-width: $layout;
  margin: 0 auto;
}

.headerBox {
  width: 100%;
  line-height: 64px;
  font-size: 14px;
  height: 64px;
  padding-top: 64px;
  // svg{
  //   display: none;
  // }
}


.headerIn {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1051;
  background:#00152A;// #fbfbfb;
  .headContent{
    width: $layout;
    margin: 0 auto;
  }
}
.devBg{
  background: #0A7EDB;
}
.loginInput {
  &>li {
    background: #00152A;
  }
}

.unLogin {
  span:nth-of-type(1) {
    margin-right: 20px;
  }
  span {
    color: #9F9F9F;
    cursor: pointer;
  }
  a{
    color: #9F9F9F;
  }
  a:hover{
    color:$theme;
  }
}

.bgf {
  background: #fff;
}
.checkbox {
  margin: 0 5px 0 0;
}
.loginInput,.registerInput {
  margin-bottom: 20px;
  .phone {
    font-weight: bold;
    font-size: 24px;
    margin: 30px 0 0;
  }
  .ps {
    font-size: 16px;
  }
  .blockBtn {
    border: 1px solid;
    height: 50px;
    margin-top: 10px;
    &:not(:last-child) {
      margin: 50px 0 0;
    }
    span {
      font-size: 20px;
      font-weight: bold;
    }
  }
  ul {
    margin-bottom: 10px;

    li {
      line-height: 40px;
      border: 1px solid $border-color;
      margin: 15px 0;

      input {
        width: 100%;
        padding-right: 15px;
        height: 40px;
        border-radius: 0;
        border: none;
        box-shadow: none !important;
      }

      input::-webkit-input-placeholder {
        color: #ccc;
      }
    }

    // li:nth-of-type(1){
    //   border: none;
    // }
    li:nth-of-type(2) {
      input {
        width: 125px;
        padding-right: 0;
      }



      img {
        width: 120px;
        height: 100%;
        cursor: pointer;
        position: absolute;
        right: 0;
      }

      .hide {
        text-align: right;
        padding-right: 20px;
        width: 120px;
        display: inline-block;

        img {
          width: 120px;
          height: auto;
          opacity: 1;
          transition: all 0.5s;
        }

        img:hover {
          width: 20px;
          height: auto;
          opacity: 0.4;
        }
      }
      .span {
        width: 120px;
        height: 100%;
        text-align: center;
        display: inline-block;
        cursor: pointer;
      }
    }

    li:nth-of-type(3) {
      input {
        width: 125px;
        padding-right: 0;
      }

      .span {
        width: 120px;
        height: 100%;
        text-align: center;
        display: inline-block;
        cursor: pointer;
      }
    }

    li:not(:first-child) {
      position: relative;
      .close {
        position: absolute;
        right:130px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .error {
    height: 40px;
    line-height: 40px;
    color: red;
  }

  .loginBtn {
    margin-top: 20px;
    margin-bottom: 10px;
    width: 100%;
    height: 50px;
    font-size: 18px;
    font-weight: bold;
    border: none;
  }
}

.cb {
  color: $theme2;
  cursor: pointer;
  padding-left: 0 !important;
  line-height: 40px;
}

.loginSuccess {
  p {
    color: #333;
    font-weight: bold;
    margin: 10px 0;
  }

  ol {
    li {
      list-style: initial;
      color: #666
    }
  }
}

.comfirm {
  position: absolute;
  top: 175px;
  right: 130px;
}

.nav {
 
  .navImage{
    width: 25px;
    vertical-align: middle;
    float: left;
    margin-right: 10px;
  }
  .default{
    color: #fff;
    font-weight: bold;
  }
  .applicationName{
    // line-height: 28px;
    text-align-last: justify;
    font-size: 18px;
    color: #fff;
  }
  li {
    // line-height: 64px;
    margin-right: 20px;
    width: 85px;
    text-align:center;
    // color: #ffffff;
    // opacity: 0.45; 
    // &:hover{
    //   opacity: 1;
    // }
    img {
      cursor: pointer;
      width: 160px;
      margin-top: -3px;
      max-height: 50px;
    }
  }

  li:nth-of-type(1) {
    // padding-left: 10px;
    font-size: 18px;
    text-align: left;
    width: 150px;
    margin-right: 30px;
  }

  a {
    display: inline-block;
    width: 100%;
    height: 100%;
    color: #9F9F9F;
  }

  .acitve {
    // color: $theme;
    color: #fff;
    font-weight: bold;
  }

  a:focus{
    text-decoration: none;
  }

  a:hover {
    color: #fff
  }
}

.fGray {
  color: #999;
}

:global(.ant-modal-close-x) {
  margin: 15px;
}

.mb40 {
  margin-bottom: 40px;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.didLogin {
height: 60px;
  cursor: pointer;
  margin-right: 25px;
  &>img{
    width: 30px;
    margin-right: 10px;
  }
  &>span{
    color: #FFF;
    line-height: 28px;
  }
  .headBox{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: #75808B;
    margin-right: 20px;
  }

  // line-height: 28px;

  // cursor: pointer;
  // padding: 0 30px 0px 5px;
  // position: relative;
  // height: 60px;
  // img {
  //   width: 25px;
  //   height: 25px;
  //   margin-right: 8px;
  // }

  // &>span {
  //   margin-right: 4px;
  //   font-size: 13px;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   white-space: nowrap;
  // }

  // i {
  //   font-size: 12px;
  // }

  // // .userInfo {
  // //   width: 100%;
  // //   background: #fbfbfb;
  // //   position: absolute;
  // //   top: 70px;
  // //   left: 0;
  // //   display: none;
  // //   cursor: pointer;

  // //   li {
  // //     width: 100%;
  // //     line-height: 35px;
  // //     text-align: center;
  // //     border-radius: 0;

  // //     a {
  // //       color: #333;
  // //       display: inline-block;
  // //       width: 100%;
  // //       height: 100%;
  // //     }
  // //   }

  // //   li:hover {
  // //     background: #eee;

  // //     a {
  // //       color: #333;
  // //     }
  // //   }

  // //   li:nth-of-type(3) {
  // //     border-top: 1px solid #eee;
  // //     padding-top: 5px;
  // //   }
  // }
}

.didLogin:hover {
  .userInfo {
    display: block;
  }
}
  // .didLogin:hover {
  //   .userInfo {
  //     display: block;
  //   }
  // }
  .active{
    font-weight: bold;
    a{
      // color:$theme;
      color:#fff;
      font-weight:bold;
    }
  }
.errorBorder {
  border: 1px solid red !important;
}

.LoginName{
  margin-right: 30px;
  &>img{
    margin-right: 5px;
  }
  &>span{
    color: #FFF;
  }
}
