@charset "utf-8";
/* CSS Document */
body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
dl,
dd,
td,
input,
fieldset,
textarea,
option {
  margin: 0;
  padding: 0;
}
body{
  background: rgba(242, 242, 242, 1) !important;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
  color: #333;
}
img {
  border: none;
  vertical-align: top;
}
a,
input {
  outline: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
b,
strong {
  font-weight: 100;
}
i,
em {
  font-style: normal;
}
body {
  font: 12px "微软雅黑";
  background-color: #F0F2F5 !important;
}

.clear {
  zoom: 1;
}
.clear:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

.fl {
  float: left;
}
.fr {
  float: right;
}
.ant-btn{
  border-radius: 4px;
}
.ant-pagination-item,.ant-pagination-prev,.ant-pagination-item-link,.ant-select-selection--single,.ant-pagination-options-quick-jumper input{
  border-radius: 4px !important;
}

.ant-table-fixed{
  z-index: 0;
}
.ant-table-fixed-right table{
  z-index: 0;
}
.ant-message{
  z-index: 9999;
}
