.loading_reqLoading__h_Mgm {
  text-align: center; }

body {
  background-color: #eee;
}

.loading2_showbox__3fcKX {
  position: fixed;
  top: 70px;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5%;
  /* background: rgba(0,0,0,0.5); */
  /* background: rgba(255, 255, 255,0.5); */
  z-index: 10000000;
  display: flex;
  /* align-items: center; */
  -webkit-transition: all .5s;
  transition: all .5s;
  justify-content: center;
}

.loading2_loader__2IsV9 {
  z-index: 500000;
  position: relative;
  margin: 0 auto;
  width: 100px;
}
.loading2_hide__3MDgP{
  opacity: 0;
  visibility: hidden;
}

@charset "UTF-8";
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none; }

input[type="number"] {
  -moz-appearance: textfield; }

body {
  background: #ffffff;
  font-size: 14px;
  padding-right: 0 !important;
  overflow: auto !important; }

a:focus {
  text-decoration: none; }

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
.header_scrollSet__2yPma {
  /*定义滚动条轨道 内阴影+圆角*/
  /*定义滑块 内阴影+圆角*/ }
  .header_scrollSet__2yPma::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #F5F5F5; }
  .header_scrollSet__2yPma::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5; }
  .header_scrollSet__2yPma::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    background-color: rgba(0, 0, 0, 0.1); }

.header_ant-modal-header__3ySIi {
  border: none;
  padding-left: 38px;
  padding-right: 38px; }
  .header_ant-modal-header__3ySIi .header_ant-modal-title__2BSez {
    line-height: 40px;
    font-size: 20px;
    font-weight: bolder; }

.header_ant-modal-body__3vz7b {
  padding-left: 38px;
  padding-right: 38px; }

.header_ant-modal-content__2RxAR {
  overflow: hidden; }

.ant-modal-close-x:before {
  color: #9f9f9f;
  font-size: 15px;
  margin: 10px; }

.ant-modal-close-x {
  color: #9f9f9f;
  font-size: 15px;
  margin: 10px;
  width: 30px;
  height: 30px;
  line-height: 30px; }

.header_center__v3VNR {
  width: 1224px;
  margin: 0 auto; }

.header_ant-upload__T4RVq.header_ant-upload-select-picture-card__wbgtI:hover, .header_ant-input__2ar_W:hover {
  border-color: #0f88eb; }

.header_flex__2-EXh {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.header_clearFix__2fuux:after {
  content: '';
  clear: both;
  visibility: hidden;
  display: block;
  height: 0; }

.header_grey__3fNtQ {
  color: #dad9d9; }

.header_show__3KqNU {
  display: block; }

.header_hide__2_JPz {
  display: none; }

.header_fl__jjQH3 {
  float: left; }

.header_num__2VZx9 {
  padding: 0 6px;
  color: #e4393c;
  font-family: "\5FAE\8F6F\96C5\9ED1   Regular", 微软雅黑;
  font-weight: 400;
  font-size: 16px;
  line-height: 58px; }

header {
  width: 100%;
  margin: 0 auto; }

.header_headerBox__29ye1 {
  width: 100%;
  line-height: 64px;
  font-size: 14px;
  height: 64px;
  padding-top: 64px; }

.header_headerIn__3olFL {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1051;
  background: #00152A; }
  .header_headerIn__3olFL .header_headContent__XzAnh {
    width: 1224px;
    margin: 0 auto; }

.header_devBg__39CVO {
  background: #0A7EDB; }

.header_loginInput__ILe8h > li {
  background: #00152A; }

.header_unLogin__2gfgF span:nth-of-type(1) {
  margin-right: 20px; }

.header_unLogin__2gfgF span {
  color: #9F9F9F;
  cursor: pointer; }

.header_unLogin__2gfgF a {
  color: #9F9F9F; }

.header_unLogin__2gfgF a:hover {
  color: #0f88eb; }

.header_bgf__1uKKR {
  background: #fff; }

.header_checkbox__JvJ88 {
  margin: 0 5px 0 0; }

.header_loginInput__ILe8h, .header_registerInput__1Rsf- {
  margin-bottom: 20px; }
  .header_loginInput__ILe8h .header_phone__d35AD, .header_registerInput__1Rsf- .header_phone__d35AD {
    font-weight: bold;
    font-size: 24px;
    margin: 30px 0 0; }
  .header_loginInput__ILe8h .header_ps__1Sm7z, .header_registerInput__1Rsf- .header_ps__1Sm7z {
    font-size: 16px; }
  .header_loginInput__ILe8h .header_blockBtn__vUM5-, .header_registerInput__1Rsf- .header_blockBtn__vUM5- {
    border: 1px solid;
    height: 50px;
    margin-top: 10px; }
    .header_loginInput__ILe8h .header_blockBtn__vUM5-:not(:last-child), .header_registerInput__1Rsf- .header_blockBtn__vUM5-:not(:last-child) {
      margin: 50px 0 0; }
    .header_loginInput__ILe8h .header_blockBtn__vUM5- span, .header_registerInput__1Rsf- .header_blockBtn__vUM5- span {
      font-size: 20px;
      font-weight: bold; }
  .header_loginInput__ILe8h ul, .header_registerInput__1Rsf- ul {
    margin-bottom: 10px; }
    .header_loginInput__ILe8h ul li, .header_registerInput__1Rsf- ul li {
      line-height: 40px;
      border: 1px solid #d9d9d9;
      margin: 15px 0; }
      .header_loginInput__ILe8h ul li input, .header_registerInput__1Rsf- ul li input {
        width: 100%;
        padding-right: 15px;
        height: 40px;
        border-radius: 0;
        border: none;
        box-shadow: none !important; }
      .header_loginInput__ILe8h ul li input::-webkit-input-placeholder, .header_registerInput__1Rsf- ul li input::-webkit-input-placeholder {
        color: #ccc; }
    .header_loginInput__ILe8h ul li:nth-of-type(2) input, .header_registerInput__1Rsf- ul li:nth-of-type(2) input {
      width: 125px;
      padding-right: 0; }
    .header_loginInput__ILe8h ul li:nth-of-type(2) img, .header_registerInput__1Rsf- ul li:nth-of-type(2) img {
      width: 120px;
      height: 100%;
      cursor: pointer;
      position: absolute;
      right: 0; }
    .header_loginInput__ILe8h ul li:nth-of-type(2) .header_hide__2_JPz, .header_registerInput__1Rsf- ul li:nth-of-type(2) .header_hide__2_JPz {
      text-align: right;
      padding-right: 20px;
      width: 120px;
      display: inline-block; }
      .header_loginInput__ILe8h ul li:nth-of-type(2) .header_hide__2_JPz img, .header_registerInput__1Rsf- ul li:nth-of-type(2) .header_hide__2_JPz img {
        width: 120px;
        height: auto;
        opacity: 1;
        -webkit-transition: all 0.5s;
        transition: all 0.5s; }
      .header_loginInput__ILe8h ul li:nth-of-type(2) .header_hide__2_JPz img:hover, .header_registerInput__1Rsf- ul li:nth-of-type(2) .header_hide__2_JPz img:hover {
        width: 20px;
        height: auto;
        opacity: 0.4; }
    .header_loginInput__ILe8h ul li:nth-of-type(2) .header_span__G6k5o, .header_registerInput__1Rsf- ul li:nth-of-type(2) .header_span__G6k5o {
      width: 120px;
      height: 100%;
      text-align: center;
      display: inline-block;
      cursor: pointer; }
    .header_loginInput__ILe8h ul li:nth-of-type(3) input, .header_registerInput__1Rsf- ul li:nth-of-type(3) input {
      width: 125px;
      padding-right: 0; }
    .header_loginInput__ILe8h ul li:nth-of-type(3) .header_span__G6k5o, .header_registerInput__1Rsf- ul li:nth-of-type(3) .header_span__G6k5o {
      width: 120px;
      height: 100%;
      text-align: center;
      display: inline-block;
      cursor: pointer; }
    .header_loginInput__ILe8h ul li:not(:first-child), .header_registerInput__1Rsf- ul li:not(:first-child) {
      position: relative; }
      .header_loginInput__ILe8h ul li:not(:first-child) .header_close__1bGcy, .header_registerInput__1Rsf- ul li:not(:first-child) .header_close__1bGcy {
        position: absolute;
        right: 130px;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%); }
  .header_loginInput__ILe8h .header_error__WuhCY, .header_registerInput__1Rsf- .header_error__WuhCY {
    height: 40px;
    line-height: 40px;
    color: red; }
  .header_loginInput__ILe8h .header_loginBtn__xucf6, .header_registerInput__1Rsf- .header_loginBtn__xucf6 {
    margin-top: 20px;
    margin-bottom: 10px;
    width: 100%;
    height: 50px;
    font-size: 18px;
    font-weight: bold;
    border: none; }

.header_cb__2oF8j {
  color: #0f88eb;
  cursor: pointer;
  padding-left: 0 !important;
  line-height: 40px; }

.header_loginSuccess__3gXmx p {
  color: #333;
  font-weight: bold;
  margin: 10px 0; }

.header_loginSuccess__3gXmx ol li {
  list-style: disc outside none;
  list-style: initial;
  color: #666; }

.header_comfirm__2ui6t {
  position: absolute;
  top: 175px;
  right: 130px; }

.header_nav__3xY4G .header_navImage__BDcy6 {
  width: 25px;
  vertical-align: middle;
  float: left;
  margin-right: 10px; }

.header_nav__3xY4G .header_default__2n94K {
  color: #fff;
  font-weight: bold; }

.header_nav__3xY4G .header_applicationName__-ylZA {
  -moz-text-align-last: justify;
       text-align-last: justify;
  font-size: 18px;
  color: #fff; }

.header_nav__3xY4G li {
  margin-right: 20px;
  width: 85px;
  text-align: center; }
  .header_nav__3xY4G li img {
    cursor: pointer;
    width: 160px;
    margin-top: -3px;
    max-height: 50px; }

.header_nav__3xY4G li:nth-of-type(1) {
  font-size: 18px;
  text-align: left;
  width: 150px;
  margin-right: 30px; }

.header_nav__3xY4G a {
  display: inline-block;
  width: 100%;
  height: 100%;
  color: #9F9F9F; }

.header_nav__3xY4G .header_acitve__1EuNM {
  color: #fff;
  font-weight: bold; }

.header_nav__3xY4G a:focus {
  text-decoration: none; }

.header_nav__3xY4G a:hover {
  color: #fff; }

.header_fGray__2WRvG {
  color: #999; }

.ant-modal-close-x {
  margin: 15px; }

.header_mb40__2qoGm {
  margin-bottom: 40px; }

.header_flex__2-EXh {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row; }

.header_didLogin__jov53 {
  height: 60px;
  cursor: pointer;
  margin-right: 25px; }
  .header_didLogin__jov53 > img {
    width: 30px;
    margin-right: 10px; }
  .header_didLogin__jov53 > span {
    color: #FFF;
    line-height: 28px; }
  .header_didLogin__jov53 .header_headBox__yOECv {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: #75808B;
    margin-right: 20px; }

.header_didLogin__jov53:hover .header_userInfo__W37_U {
  display: block; }

.header_active__1pbku {
  font-weight: bold; }
  .header_active__1pbku a {
    color: #fff;
    font-weight: bold; }

.header_errorBorder__uXkJi {
  border: 1px solid red !important; }

.header_LoginName__1wUz0 {
  margin-right: 30px; }
  .header_LoginName__1wUz0 > img {
    margin-right: 5px; }
  .header_LoginName__1wUz0 > span {
    color: #FFF; }

.loginModal .ant-input-group-addon {
    background-color: #fff;
    width: 35px !important;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
    border: none;
}

.loginModal .ant-input-group-addon .icon {
    height: 15px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    width:15px;

}

.loginModal .addonAfter .ant-input-wrapper .ant-input-group-addon:last-child {
    border-left: 1px solid #d9d9d9;
    background: #fafafa;
}

.ant-input-disabled {
    background-color: #fff;
    opacity: 1;
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.25);
}

/* .ant-dropdown {
    z-index: 1;
} */
@charset "UTF-8";
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none; }

input[type="number"] {
  -moz-appearance: textfield; }

body {
  background: #ffffff;
  font-size: 14px;
  padding-right: 0 !important;
  overflow: auto !important; }

a:focus {
  text-decoration: none; }

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
.Bread_scrollSet__2pg9r {
  /*定义滚动条轨道 内阴影+圆角*/
  /*定义滑块 内阴影+圆角*/ }
  .Bread_scrollSet__2pg9r::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #F5F5F5; }
  .Bread_scrollSet__2pg9r::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5; }
  .Bread_scrollSet__2pg9r::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    background-color: rgba(0, 0, 0, 0.1); }

.Bread_ant-modal-header__LoiE7 {
  border: none;
  padding-left: 38px;
  padding-right: 38px; }
  .Bread_ant-modal-header__LoiE7 .Bread_ant-modal-title__2GG6y {
    line-height: 40px;
    font-size: 20px;
    font-weight: bolder; }

.Bread_ant-modal-body__uiSde {
  padding-left: 38px;
  padding-right: 38px; }

.Bread_ant-modal-content__1dSo1 {
  overflow: hidden; }

.ant-modal-close-x:before {
  color: #9f9f9f;
  font-size: 15px;
  margin: 10px; }

.ant-modal-close-x {
  color: #9f9f9f;
  font-size: 15px;
  margin: 10px;
  width: 30px;
  height: 30px;
  line-height: 30px; }

.Bread_center__3cSrc {
  width: 1224px;
  margin: 0 auto; }

.Bread_ant-upload__1j9VT.Bread_ant-upload-select-picture-card__1RSnT:hover, .Bread_ant-input__m_bfw:hover {
  border-color: #0f88eb; }

.Bread_flex__X0Bnw {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.Bread_clearFix__3aWG7:after {
  content: '';
  clear: both;
  visibility: hidden;
  display: block;
  height: 0; }

.Bread_grey__2-S4D {
  color: #dad9d9; }

.Bread_show__ai0KZ {
  display: block; }

.Bread_hide__300Sp {
  display: none; }

.Bread_fl__2t_M0 {
  float: left; }

.Bread_num__352qS {
  padding: 0 6px;
  color: #e4393c;
  font-family: "\5FAE\8F6F\96C5\9ED1   Regular", 微软雅黑;
  font-weight: 400;
  font-size: 16px;
  line-height: 58px; }

.Bread_Bread__3pT0K {
  display: inline-block;
  width: 100%; }

.Bread_breadMain__2FJRg {
  font-size: 14px;
  font-weight: 400;
  color: #999;
  background-color: white;
  padding: 20px 25px 0px 25px;
  width: 1224px;
  margin: 0 auto; }
  .Bread_breadMain__2FJRg .Bread_desc__LDybJ {
    line-height: 30px; }

@charset "UTF-8";
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none; }

input[type="number"] {
  -moz-appearance: textfield; }

body {
  background: #ffffff;
  font-size: 14px;
  padding-right: 0 !important;
  overflow: auto !important; }

a:focus {
  text-decoration: none; }

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
.scrollSet {
  /*定义滚动条轨道 内阴影+圆角*/
  /*定义滑块 内阴影+圆角*/ }
  .scrollSet::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #F5F5F5; }
  .scrollSet::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5; }
  .scrollSet::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    background-color: rgba(0, 0, 0, 0.1); }

.ant-modal-header {
  border: none;
  padding-left: 38px;
  padding-right: 38px; }
  .ant-modal-header .ant-modal-title {
    line-height: 40px;
    font-size: 20px;
    font-weight: bolder; }

.ant-modal-body {
  padding-left: 38px;
  padding-right: 38px; }

.ant-modal-content {
  overflow: hidden; }

.ant-modal-close-x:before {
  color: #9f9f9f;
  font-size: 15px;
  margin: 10px; }

.ant-modal-close-x {
  color: #9f9f9f;
  font-size: 15px;
  margin: 10px;
  width: 30px;
  height: 30px;
  line-height: 30px; }

.center {
  width: 1224px;
  margin: 0 auto; }

.ant-upload.ant-upload-select-picture-card:hover, .ant-input:hover {
  border-color: #0f88eb; }

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.clearFix:after {
  content: '';
  clear: both;
  visibility: hidden;
  display: block;
  height: 0; }

.grey {
  color: #dad9d9; }

.show {
  display: block; }

.hide {
  display: none; }

.fl {
  float: left; }

.num {
  padding: 0 6px;
  color: #e4393c;
  font-family: "\5FAE\8F6F\96C5\9ED1   Regular", 微软雅黑;
  font-weight: 400;
  font-size: 16px;
  line-height: 58px; }

@charset "utf-8";
/* CSS Document */
body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
dl,
dd,
td,
input,
fieldset,
textarea,
option {
  margin: 0;
  padding: 0;
}
body{
  background: rgba(242, 242, 242, 1) !important;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
  color: #333;
}
img {
  border: none;
  vertical-align: top;
}
a,
input {
  outline: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
b,
strong {
  font-weight: 100;
}
i,
em {
  font-style: normal;
}
body {
  font: 12px "\5FAE\8F6F\96C5\9ED1";
  background-color: #F0F2F5 !important;
}

.clear {
  zoom: 1;
}
.clear:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

.fl {
  float: left;
}
.fr {
  float: right;
}
.ant-btn{
  border-radius: 4px;
}
.ant-pagination-item,.ant-pagination-prev,.ant-pagination-item-link,.ant-select-selection--single,.ant-pagination-options-quick-jumper input{
  border-radius: 4px !important;
}

.ant-table-fixed{
  z-index: 0;
}
.ant-table-fixed-right table{
  z-index: 0;
}
.ant-message{
  z-index: 9999;
}


