.loginModal .ant-input-group-addon {
    background-color: #fff;
    width: 35px !important;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
    border: none;
}

.loginModal .ant-input-group-addon .icon {
    height: 15px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width:15px;

}

.loginModal .addonAfter .ant-input-wrapper .ant-input-group-addon:last-child {
    border-left: 1px solid #d9d9d9;
    background: #fafafa;
}

.ant-input-disabled {
    background-color: #fff;
    opacity: 1;
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.25);
}

/* .ant-dropdown {
    z-index: 1;
} */