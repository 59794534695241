$env: undefined;
$layout:1224px;
$theme:#0f88eb;
$theme2:#0f88eb;
$borderColor:#E4E4E4;
$grayColor:#CCCCCC;
$public_url:'';//if($env=="prod", '//anju.cdn.shenzhenzjj.com', '');
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}
body{
  background: #ffffff;
  font-size: 14px;
  padding-right: 0!important;
  overflow: auto!important;
}
 
a:focus{
  text-decoration: none;
}
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
.scrollSet{
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #F5F5F5;
  }

  /*定义滚动条轨道 内阴影+圆角*/
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
  }

  /*定义滑块 内阴影+圆角*/
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    background-color: rgba(0, 0, 0, 0.1);
  }
}
// 模态框基础样式
.ant-modal-header {
  border: none;
  padding-left: 38px;
  padding-right: 38px;
  .ant-modal-title {
    line-height: 40px;
    font-size: 20px;
    font-weight: bolder;
  }
}
.ant-modal-body {
  padding-left: 38px;
  padding-right: 38px;
}
.ant-modal-content {
  overflow: hidden;
}
:global(.ant-modal-close-x:before) {
  color: #9f9f9f;
  font-size: 15px;
  margin: 10px;
}
:global(.ant-modal-close-x) {
  color: #9f9f9f;
  font-size: 15px;
  margin: 10px;
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.center {
  width: $layout;
  margin: 0 auto
}

//primary主题颜色
.ant-upload.ant-upload-select-picture-card:hover,.ant-input:hover {
  border-color: $theme2;
}
.flex{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.clearFix {
  &:after {
    content: '';
    clear: both;
    visibility: hidden;
    display: block;
    height: 0;
  }
}
.grey {
  color: #dad9d9;
}
.show{
  display:block;
}
.hide{
  display: none;
}

.fl{
  float: left;
}
.num {
  padding: 0 6px;
  color: rgb(228, 57, 60);
  font-family: "微软雅黑 Regular", 微软雅黑;
  font-weight: 400;
  font-size: 16px;
  line-height: 58px;
}