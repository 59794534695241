body {
  background-color: #eee;
}

.showbox {
  position: fixed;
  top: 70px;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5%;
  /* background: rgba(0,0,0,0.5); */
  /* background: rgba(255, 255, 255,0.5); */
  z-index: 10000000;
  display: flex;
  /* align-items: center; */
  transition: all .5s;
  justify-content: center;
}

.loader {
  z-index: 500000;
  position: relative;
  margin: 0 auto;
  width: 100px;
}
.hide{
  opacity: 0;
  visibility: hidden;
}
